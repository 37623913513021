var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-text-field',{attrs:{"solo":"","id":"search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.listing},on:{"click:row":_vm.view},scopedSlots:_vm._u([{key:"item.repeatable",fn:function(ref){
var item = ref.item;
return [_c('IconQuestionCollectionRepeatable',{attrs:{"show":item.repeatable}})]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('DateUserCell',{attrs:{"action":"created","date":item.created,"user":item.userCreated}})]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_c('DateUserCell',{attrs:{"action":"modified","date":item.modified,"user":item.userModified}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center text-no-wrap"},[_c('ButtonEditAction',{attrs:{"loading":_vm.getting && _vm.questionCollectionId === item.id,"disabled":_vm.busy && _vm.questionCollectionId === item.id},on:{"click":function($event){$event.stopPropagation();return _vm.showQuestionCollectionDialog(item.id)}}}),_c('ButtonDeleteAction',{attrs:{"loading":_vm.deleting && _vm.questionCollectionId === item.id,"disabled":_vm.busy && _vm.questionCollectionId === item.id},on:{"click":function($event){$event.stopPropagation();return _vm.deleteQuestionCollection(item.id)}}})],1)]}}])}),(_vm.questionCollectionDialogShown)?_c('QuestionCollectionDialog',{attrs:{"questionCollectionId":_vm.questionCollectionId},on:{"close":_vm.closeQuestionCollectionDialog}}):_vm._e(),_c('NotificationBar',{attrs:{"snackbar":_vm.snackbar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }