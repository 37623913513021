





















import Vue from 'vue';
import Component from 'vue-class-component';
import QuestionCollectionList from '@/components/question-collections/QuestionCollectionList.vue';
import Page from '@/components/ui/Page.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import {Snackbar} from '@/interfaces';
import QuestionCollectionDialog from '@/components/question-collections/QuestionCollectionDialog.vue';
import NewButton from '@/components/ui/NewButton.vue';
import {questionCollectionModule} from '@/store/modules';
import NotificationBar from '@/components/ui/NotificationBar.vue';

@Component({
  components: {
    NotificationBar,
    NewButton,
    QuestionCollectionDialog,
    QuestionCollectionList,
    PageTitle,
    Page,
  },
})
export default class QuestionCollection extends Vue {
  private snackbar: Snackbar = {visible: false, message: ''};
  private questionCollectionDialogShown = false;
  private questionCollectionId: string | null = null;

  get saving(): boolean {
    return questionCollectionModule.saving || questionCollectionModule.loading;
  }

  async showQuestionCollectionDialog(): Promise<void> {
    await questionCollectionModule.loadById('new');
    const qc = await questionCollectionModule.save('new');
    this.questionCollectionId = qc.id;
    this.questionCollectionDialogShown = true;
  }

  closeQuestionCollectionDialog(message?: string) {
    this.questionCollectionDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }
}
