




















































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  QuestionCollectionAttributes,
  QuestionCollectionResource,
  Snackbar,
} from '@/interfaces';
import {questionCollectionModule} from '@/store/modules';
import QuestionCollectionDialog from '@/components/question-collections/QuestionCollectionDialog.vue';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import ButtonDeleteAction from '@/components/ui/ButtonDeleteAction.vue';
import DateUserCell from '@/components/ui/DateUserCell.vue';
import IconQuestionCollectionRepeatable from '@/components/surveys-edit/IconQuestionCollectionRepeatable.vue';

@Component({
  components: {
    IconQuestionCollectionRepeatable,
    DateUserCell,
    ButtonDeleteAction,
    ButtonEditAction,
    NotificationBar,
    QuestionCollectionDialog,
  },
})
export default class QuestionCollectionList extends Vue {
  private headers = [
    {text: 'Label', value: 'label'},
    {text: 'Key', value: 'key'},
    {text: 'Repeatable', value: 'repeatable'},
    {text: 'Created', value: 'created'},
    {text: 'Modified', value: 'modified'},
    {text: 'Actions', value: 'actions'},
  ];
  private search = '';
  private snackbar: Snackbar = {visible: false, message: ''};
  private questionCollectionId: null | string = null;
  private questionCollectionDialogShown = false;

  get busy(): boolean {
    return questionCollectionModule.busy;
  }

  get deleting(): boolean {
    return questionCollectionModule.deleting;
  }

  get getting(): boolean {
    return questionCollectionModule.getting;
  }

  get list(): QuestionCollectionAttributes[] {
    return questionCollectionModule.list.map(qc => qc.attributes);
  }

  get listing(): boolean {
    return questionCollectionModule.listing;
  }

  async created() {
    try {
      await questionCollectionModule.loadMulti();
    } catch (err) {
      console.error(err.response.data);
    }
  }

  showQuestionCollectionDialog(questionCollectionId?: string): void {
    this.questionCollectionId =
      questionCollectionId === undefined ? null : questionCollectionId;
    this.questionCollectionDialogShown = true;
  }

  closeQuestionCollectionDialog(message?: string): void {
    this.questionCollectionDialogShown = false;
    if (message !== undefined) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  async deleteQuestionCollection(questionCollectionId: string): Promise<void> {
    this.questionCollectionId = questionCollectionId;
    const questionCollection = questionCollectionModule.find(
      questionCollectionId
    );
    const result = confirm(
      `Are you sure you want to delete the question collection labeled ${questionCollection.attributes.label}?`
    );
    if (result) {
      try {
        await questionCollectionModule.delete(questionCollectionId);
        this.snackbar.message = 'Question collection deleted';
        this.snackbar.visible = true;
      } catch (err) {
        const error = err.response?.data?.errors[0]?.detail;
        this.snackbar.message = error ? error : 'Error';
        this.snackbar.visible = true;
        this.snackbar.timeout = 10000;
      }
    }
  }

  view(item: QuestionCollectionResource): void {
    this.showQuestionCollectionDialog(item.id);
  }
}
