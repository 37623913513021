









































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  AlertMessage,
  QuestionCollectionResource,
  QuestionResource,
  Snackbar,
} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {questionModule, questionCollectionModule} from '@/store/modules';
import SurveyField from '@/components/surveys/SurveyField.vue';
import QuestionDialog from '@/components/question-collections/QuestionDialog.vue';
import {
  mdiAccountQuestion,
  mdiAccountLock,
  mdiPlus,
  mdiDragHorizontal,
} from '@mdi/js';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import DialogActions from '@/components/ui/DialogActions.vue';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import ButtonDeleteAction from '@/components/ui/ButtonDeleteAction.vue';
import Dialog from '@/components/ui/Dialog.vue';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {rulesRequired, urlFriendly} from '@/utility';
import draggable from 'vuedraggable';
import {orderBy} from 'lodash';

@Component({
  components: {
    RequiredLabel,
    Dialog,
    ButtonDeleteAction,
    ButtonEditAction,
    DialogActions,
    NotificationBar,
    SurveyField,
    QuestionDialog,
    DialogAlert,
    draggable,
  },
})
export default class QuestionCollectionDialog extends Vue {
  @Prop() questionCollectionId!: string;
  @Prop({default: false}) newItem!: boolean;
  private alert: AlertMessage | null = null;
  private iconAdd = mdiPlus;
  private iconDrag = mdiDragHorizontal;
  private iconUserQuestion = mdiAccountLock;
  private iconSurveyQuestion = mdiAccountQuestion;
  private questionDialogShown = false;
  private questionId: string | null = null;
  private snackbar: Snackbar = {visible: false, message: ''};
  private rulesRequired = rulesRequired;
  private valid = false;
  private nameTouched = false;
  private newQuestion = false;

  get deleting(): boolean {
    return questionCollectionModule.deleting;
  }

  get busy(): boolean {
    return questionCollectionModule.busy;
  }

  get getting(): boolean {
    return questionCollectionModule.reading;
  }

  get questionBusy(): boolean {
    return questionModule.busy;
  }

  get questionDeleting(): boolean {
    return questionModule.deleting;
  }

  get questionListing(): boolean {
    return questionModule.listing;
  }

  get questionGetting(): boolean {
    return questionModule.getting;
  }

  get questionSaving(): boolean {
    return questionModule.saving;
  }

  get questions(): QuestionResource[] {
    return orderBy(questionModule.list, ['attributes.order']);
  }

  set questions(questions: QuestionResource[]) {
    questionModule.reorder(questions);
  }

  get questionCollection(): QuestionCollectionResource {
    return questionCollectionModule.find(this.questionCollectionId);
  }

  get saving(): boolean {
    return questionCollectionModule.saving;
  }

  async created(): Promise<void> {
    try {
      await Promise.all([
        questionCollectionModule.loadById(this.questionCollectionId),
        questionModule.loadMulti({
          filters: {questionCollectionId: this.questionCollectionId},
        }),
      ]);
      if (this.questionCollection.attributes.key) {
        this.nameTouched = true;
      }
    } catch (err) {
      console.error(
        `There was an error loading questions in collection ${this.questionCollectionId}`
      );
    }
  }

  async cancel() {
    if (this.newItem) {
      await questionCollectionModule.delete(this.questionCollectionId);
    }
    this.$emit('close');
  }

  closeQuestionDialog(message?: string) {
    this.questionDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  async deleteQuestion(questionId: string): Promise<void> {
    this.questionId = questionId;
    const question = questionModule.find(questionId);
    const result = confirm(
      `Are you sure you want to delete the question labelled ${question.attributes.label}?`
    );
    if (result) {
      await questionModule.delete(questionId);
      this.snackbar.visible = true;
      this.snackbar.message = 'Question deleted.';
    }
  }

  async save() {
    try {
      await questionCollectionModule.save(this.questionCollectionId);
      this.$emit('close', 'Question collection saved successfully');
    } catch (err) {
      this.alert = {
        message: 'Could not save question collection',
        type: 'error',
      };
    }
  }

  async showQuestionDialog(questionId: string) {
    this.questionId = questionId;
    this.newQuestion = false;
    if (questionId === 'new') {
      this.newQuestion = true;
      await questionModule.loadById('new');
      let question = questionModule.find('new');
      question.attributes.questionCollectionId = parseInt(
        this.questionCollectionId
      );
      question = await questionModule.save('new');
      this.questionId = question.id;
    }
    this.questionDialogShown = true;
  }

  generateName(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    const name = urlFriendly(value);
    //console.log(event.target.value, name);
    if (!this.nameTouched) {
      this.questionCollection.attributes.key = name;
    }
  }

  async questionMoved(): Promise<void> {
    const orderedIds = this.questions.map(field => field.id);
    console.log(orderedIds);
    await questionModule.action({
      attributes: {
        action: 'reorder',
        questionCollectionId: this.questionCollectionId,
        ids: orderedIds,
      },
    });

    this.snackbar.visible = true;
    this.snackbar.message = 'Questions order saved.';
  }
}
