











































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  AlertMessage,
  QuestionItemResource,
  QuestionResource,
} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {questionModule} from '@/store/modules';
import SurveyFieldTypeAutocomplete from '@/components/surveys-edit/SurveyFieldTypeAutocomplete.vue';
import {questionItemModule} from '@/store/modules/questionItemModule';
import {QuestionType} from '@/enums';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {rulesRequired, urlFriendly} from '@/utility';
import DateRangeQuestion from '@/components/questions/DateRangeQuestion.vue';
import DateTimeRangeQuestion from '@/components/questions/DateTimeRangeQuestion.vue';
import FileQuestion from '@/components/questions/FileQuestion.vue';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import DialogActions from '@/components/ui/DialogActions.vue';
import Dialog from '@/components/ui/Dialog.vue';
import QuestionDialogItemList from '@/components/question-collections/QuestionDialogItemList.vue';

@Component({
  components: {
    QuestionDialogItemList,
    Dialog,
    DialogActions,
    DialogAlert,
    FileQuestion,
    DateTimeRangeQuestion,
    DateRangeQuestion,
    RequiredLabel,
    SurveyFieldTypeAutocomplete,
  },
})
export default class QuestionDialog extends Vue {
  @Prop() questionId!: string;
  @Prop() newItem!: string;
  private alert: AlertMessage | null = null;
  private nameTouched = false;
  private questionType = QuestionType;
  private rulesRequired = rulesRequired;
  private valid = false;

  get busy(): boolean {
    return questionModule.busy || questionItemModule.busy;
  }

  get deleting(): boolean {
    return questionModule.deleting;
  }

  get hasItems(): boolean {
    switch (this.question.attributes.type) {
      case QuestionType.Dropdown:
      case QuestionType.CheckboxGroup:
      case QuestionType.RadioGroup:
        return true;
      default:
        return false;
    }
  }

  get loading(): boolean {
    return questionModule.loading;
  }

  get loadingQuestionItems(): boolean {
    return questionItemModule.loading;
  }

  get question(): QuestionResource {
    return questionModule.find(this.questionId);
  }

  get questionItems(): QuestionItemResource[] {
    return questionItemModule.list;
  }

  get saving(): boolean {
    return questionModule.saving || questionItemModule.saving;
  }

  async created() {
    try {
      await questionModule.loadById(this.questionId);
      await questionItemModule.loadMulti({
        filters: {questionId: this.questionId},
      });
      if (this.question.attributes.name) {
        this.nameTouched = true;
      }
    } catch (err) {
      console.error(
        `There was an error loading question ${this.questionId} or getting question`
      );
    }
  }

  async addItem() {
    await questionItemModule.loadById('new');
    await questionItemModule.save('new');
  }

  async cancel() {
    if (this.newItem) {
      await questionModule.delete(this.questionId);
    } else {
      // reload the question and items in case they were changed
      await Promise.all([
        questionModule.loadById(this.questionId),
        questionItemModule.loadMulti({
          filters: {questionId: this.questionId},
        }),
      ]);
    }
    this.$emit('close');
  }

  async save() {
    try {
      if (this.hasItems) {
        await this.saveItems();
      }
      const q = await questionModule.save(this.questionId);
      this.questionId = q.id;
      this.$emit('close', 'Question saved successfully');
    } catch (err) {
      this.alert = {message: 'Could not save question', type: 'error'};
    }
  }

  async saveItems() {
    for (const item of this.question.attributes.items) {
      const itemId = String(item.id);
      try {
        await questionItemModule.save(itemId);
        this.alert = null;
      } catch (err) {
        console.error(err);
        const error = err.response?.data?.errors[0]?.detail;
        this.alert = {
          message: error ? error : 'Error',
          type: 'error',
        };
      }
    }
  }

  generateName(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    const name = urlFriendly(value);
    //console.log(event.target.value, name);
    if (!this.nameTouched) {
      this.question.attributes.name = name;
    }
  }
}
